@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto';
}

/* code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
